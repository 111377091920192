import React from 'react';

const StepBox = ({step,title,content}) => {
    return (
      <div className="step-box">
         <div className="step-num-holder">
          <div className="step-num">{step}</div>
         </div>
         <div className="step-title">{title}</div>
         <div className="step-content">{content}</div>
      </div>
    );
}
export default StepBox;