import React from 'react';
import StepBox from './StepBox';

const BgBox = ({autoHeight,showTitle,background,content,createRoomLink,howItWorksLink,contactUsLink,videoURL,showSteps,step1Title,step1Content,step2Title,step2Content,step3Title,step3Content}) => {
  const className = "bg-box " + (autoHeight===true ? "bg-box-auto" : "bg-box-full") ;  
  return (
      <div className={className}  style={{backgroundImage: `url(${background})`, backgroundRepeat: "no-repeat"}}>
          <div className="content-box">
            {showTitle && <div className='main-title'>Reception<span className="blue-text">Room</span></div>}
            <div className="content-text">{content}</div>
            {videoURL && <div className="video-content"><iframe title="Video Content" src={videoURL} /></div>}
            {showSteps && <div>
                <StepBox step={1} title={step1Title} content={step1Content}/>
                <StepBox step={2} title={step2Title} content={step2Content}/>
                <StepBox step={3} title={step3Title} content={step3Content}/>
            </div>}
            <div className="button blue-button"><a href={createRoomLink} rel="noreferrer" target="_blank">Create Room</a></div>
            {howItWorksLink && <div className="button white-button"><a href={howItWorksLink} rel="noreferrer" target="_blank">How it works</a></div>}
            {contactUsLink && <div className="contact-us-button"><a href={contactUsLink} rel="noreferrer" target="_blank">Feedback</a></div>}
          </div>
      </div>
    );
}
export default BgBox;