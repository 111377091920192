import React, { useState } from 'react';
import BgBox from './BgBox';
import Backendless from 'backendless';

const Home = () => {
  
  
  const [websiteInfo, setWebsiteInfo] = useState(null);

  const loadWebsiteInfo = () => {
    const whereClause = `key = 'website_info'`;
      let queryBuilder = Backendless.DataQueryBuilder.create().setWhereClause( whereClause );
      queryBuilder.addProperties('data');
      Backendless.Data.of( "system_configuration" ).find( queryBuilder )
      .then(function(info){
        if(info && info.length>0){
          let defaultInfo = JSON.parse(info[0].data);
          console.log(defaultInfo.bg1);
          setWebsiteInfo(defaultInfo);
        }
      })
      .catch( function( error ) {
          console.log(error);
      });
  }

  if(websiteInfo === null)
    loadWebsiteInfo();


  
    return (
      <div className="home">
        {websiteInfo && <BgBox 
                          autoHeight={false}
                          showTitle={true} 
                          content={websiteInfo.content1} 
                          background={websiteInfo.bg1}
                          createRoomLink={websiteInfo.roomLink}
                          //howItWorksLink={websiteInfo.howItWorksLink}
                          contactUsLink={websiteInfo.contactUsLink}
                          />}
        {websiteInfo && <BgBox 
                          autoHeight={false}
                          showTitle={false} 
                          content={websiteInfo.content2} 
                          background={websiteInfo.bg2}
                          createRoomLink={websiteInfo.roomLink}
                          //howItWorksLink={websiteInfo.howItWorksLink}
                          contactUsLink={websiteInfo.contactUsLink}
                          />}
         {websiteInfo && <BgBox 
                          autoHeight={true}
                          showTitle={false} 
                          content={websiteInfo.content3} 
                          background={websiteInfo.bg3}
                          createRoomLink={websiteInfo.roomLink}
                          contactUsLink={websiteInfo.contactUsLink}
                          videoURL={websiteInfo.videoURL}
                          showSteps={true}
                          step1Title={websiteInfo.step1Title}
                          step1Content={websiteInfo.step1Content}
                          step2Title={websiteInfo.step2Title}
                          step2Content={websiteInfo.step2Content}
                          step3Title={websiteInfo.step3Title}
                          step3Content={websiteInfo.step3Content}
                          />}
      </div>
    );
}
export default Home;